<template>
  <section class="
      flex flex-col justify-center h-screen
    ">
    <div class="flex flex-col items-center space-y-4">
      <div class="w-40 animate-pulse">
        <img src="../../assets/images/nasida-logo.svg">
      </div>
      <h1 class="text-center text-nasida-green font-bold font-sans md:text-3xl lg:text-4xl 2xl:text-5xl">
        Site is currently <span class="font-medium">under construction</span>
      </h1>
    </div>
  </section>
</template>
<script src="./construction.page.js"></script>
<style src="./construction.page.css" scoped lang="css"></style>

